
import React, { useEffect, useState } from "react";
import * as productApi from "apis/productApi";
import * as categoryApi from "apis/categoryApi";
import { downloadFile } from "../../constants/function";
import { Row, Col, Button, Input, Label } from "reactstrap";
import { TreeSelect } from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useQuery } from 'Utils/UseQuery';
import { withRouter } from 'react-router-dom';
import { showMessage } from "Utils/global";
import { useFetchBrands } from "components/Hook/useFetchBrands";

function ListProductFilter(props) {
  let history = useHistory();
  const selectedShop = useSelector(state => state.SelectedShop);
  const { brands } = useFetchBrands(selectedShop);

  const [listCategory, setListCategory] = useState([]);
  const [webCategories, setWebCategories] = useState([]);
  const [isShowSearch, setIsShowSearch] = useState(false);

  const FILTER_TYPE = {
    STRING: 0,
    CHECK: 1
  };

  const [filter, setFilter] = useState({
    code: "",
    name: "",
    cat: "",
    web_cat: "",
    brand: "",
    att: "",
    code_anh: "",
    from_price: "",
    to_price: "",
    no_web_cat: false,
    no_brand: false,
    no_code_anh: false,
    no_photo: false,
    no_content: false,
    tmp_image: false,
    not_post_insta: false,
    not_post_fb_24cara: false,
    not_post_fb_24cara_hn: false,
    not_post_fb_24cara_sg: false,
  });

  const filterTypes = {
    code: FILTER_TYPE.STRING,
    name: FILTER_TYPE.STRING,
    cat: FILTER_TYPE.STRING,
    web_cat: FILTER_TYPE.STRING,
    brand: FILTER_TYPE.STRING,
    att: FILTER_TYPE.STRING,
    code_anh: FILTER_TYPE.STRING,
    from_price: FILTER_TYPE.STRING,
    to_price: FILTER_TYPE.STRING,
    no_web_cat: FILTER_TYPE.CHECK,
    no_brand: FILTER_TYPE.CHECK,
    no_code_anh: FILTER_TYPE.CHECK,
    no_photo: FILTER_TYPE.CHECK,
    no_content: FILTER_TYPE.CHECK,
    tmp_image: FILTER_TYPE.CHECK,
    not_post_insta: FILTER_TYPE.CHECK,
    not_post_fb_24cara: FILTER_TYPE.CHECK,
    not_post_fb_24cara_hn: FILTER_TYPE.CHECK,
    not_post_fb_24cara_sg: FILTER_TYPE.CHECK
  };

  const filterToQueryString = () => {

    let queryParams = [];

    Object.entries(filter).map(([key, value]) => {
      switch (filterTypes[key]) {
        case FILTER_TYPE.STRING:
          if (value) {
            queryParams.push(key + '=' + encodeURIComponent(value));
          }
          break;
        case FILTER_TYPE.CHECK:
          queryParams.push(key + '=' + (value ? '1' : '0'));
          break;
      }
    });

    queryParams.push('company_id=' + encodeURIComponent(selectedShop));

    let rdn = Math.floor(Math.random() * 10) + 1;
    queryParams.push('rdn=' + encodeURIComponent(rdn.toString()));

    let queryString = queryParams.join('&');
    return queryString;
  }

  useEffect(() => {

    let query = useQuery(props.location.search);

    let params = Object.keys(filter).reduce((acc, key) => {
      let value;
      switch (filterTypes[key]) {
        case FILTER_TYPE.STRING:
          value = (query.get(key) ?? '');
          break;
        case FILTER_TYPE.CHECK:
          value = (query.get(key) == '1');
          break;
      }
      return Object.assign({}, acc, {
        [key]: value
      });
    }, {})

    setFilter(params);
  }, []);

  const setFilterValue = (value, filterId) => {
    if (value === undefined)
      value = '';
    setFilter({ ...filter, [filterId]: value })
  }

  useEffect(() => {
    handleSearch();
    updateQueryStr();
  }, [props.location.search]);

  const handleOnSearch = () => {
    let url = props.location.pathname;
    let queryString = filterToQueryString()
    url = url + '?' + queryString
    history.push(url);
  }

  const handleResetFilter = () => {
    setFilter({
      code: "",
      name: "",
      cat: "",
      web_cat: "",
      att: "",
      code_anh: "",
      from_price: "",
      to_price: "",
      no_web_cat: false,
      no_code_anh: false,
      no_photo: false,
      no_content: false,
      not_post_insta: false,
      not_post_fb_24cara: false,
      not_post_fb_24cara_hn: false,
      not_post_fb_24cara_sg: false,
    });
  }

  const handleOnExport = () => {

    const queryString = filterToQueryString();
    const queryParams = new URLSearchParams(queryString);
    const filterParams = initFilterParams(queryParams);

    productApi.exportProducts(filterParams, downloadExcel, showMessage);
  }

  const downloadExcel = (res) => {
    downloadFile(res.data.data);
  }

  useEffect(() => {
    handleSearch();
    getListCategory(selectedShop);
    fetchTreeWebCategory();
  }, [selectedShop]);

  const getListCategory = (selectedShop) => {
    categoryApi.getAllCategory(selectedShop, dataCategorySuccess, showMessage);
  }
  const dataCategorySuccess = (res) => {
    setListCategory(res.data.data);
  }
  const fetchTreeWebCategory = () => {

    if (!selectedShop) return;

    let params = [
      { name: 'company_id', value: selectedShop },
    ]

    categoryApi.getTreeWebCategory(params, fetchTreeWebCatSuccess, showMessage)
  }
  const fetchTreeWebCatSuccess = (res) => {
    let webCategories = res.data.data
    setWebCategories(webCategories);
  }

  const initPageParam = (queryParams) => {

    const params = [];
    let page = queryParams.get("page");

    if (page) {
      params.push({ name: "page", value: page });
    }

    return params;
  }

  const initFilterParams = (queryParams) => {

    const params = [];

    queryParams.forEach((value, key) => {
      params.push({ name: key, value: value });
    });

    params.push({ name: "company_id", value: queryParams.get("selectedShop") ?? selectedShop });

    return params;
  }

  const updateQueryStr = () => {
    let queryStr = props.location.search.replaceAll('?', '');
    let queryArr = queryStr.split('&');
    let newQueryArr = queryArr.filter(query => !query.startsWith('page='));
    queryStr = newQueryArr.join('&')
    props.updateQueryString(queryStr)
  }
  const handleSearch = () => {

    if (selectedShop === 0) return

    let queryParams = useQuery(props.location.search);
    const pageParam = initPageParam(queryParams);
    const filterParams = initFilterParams(queryParams);
    let params = filterParams.concat(pageParam);

    productApi.getList(params, props.populateProducts, showMessage);
  };

  const onClose = () => {
    setIsShowSearch(!isShowSearch);
  }

  return (
    <>
      {
        isShowSearch ?
          <>
            <Row className='align-items-end'>
              <Col xl={2} xs={6} className="mb-3">
                <label className='body-2 font-weight-bold'> Mã SP </label>
                <Input type='text' className="form-control" value={filter.code} onChange={(e) => setFilterValue(e.target.value, 'code')} />
              </Col>

              <Col xl={2} xs={6} className="mb-3">
                <label className='body-2 font-weight-bold'> Code ảnh </label>
                <Input type='text' className="form-control" value={filter.code_anh} onChange={(e) => setFilterValue(e.target.value, 'code_anh')} />
              </Col>

              <Col xl={2} xs={6} className="mb-3">
                <label className='body-2 font-weight-bold'> Tên SP </label>
                <Input type='text' className="form-control" value={filter.name} onChange={(e) => setFilterValue(e.target.value, 'name')} />
              </Col>

              <Col xl={2} xs={6} className="mb-3">
                <label className='body-2 font-weight-bold'> Thuộc tính </label>
                <Input type='text' className="form-control" value={filter.att} onChange={(e) => setFilterValue(e.target.value, 'att')} />
              </Col>

              <Col xl={2} xs={6} className="mb-3">
                <label className='body-2 font-weight-bold'> Từ giá </label>
                <Input type='number' className="form-control" value={filter.from_price} onChange={(e) => setFilterValue(e.target.value, 'from_price')} />
              </Col>

              <Col xl={2} xs={6} className="mb-3">
                <label className='body-2 font-weight-bold'> Đến giá </label>
                <Input type='number' className="form-control" value={filter.to_price} onChange={(e) => setFilterValue(e.target.value, 'to_price')} />
              </Col>
            </Row>

            <Row>
              <Col xl={2} className="mb-3">
                <label className='body-2 font-weight-bold'>
                  Nhóm SP kho
                </label>
                <Input type='select' value={filter.cat}
                  onChange={(e) => setFilterValue(e.target.value, 'cat')}
                >
                  <option value={""}></option>
                  {
                    listCategory.map((item, i) => (
                      <option value={item.category_id} key={i}>{item.category_name}</option>
                    ))
                  }
                </Input>
              </Col>

              <Col xl={6} className="mb-3">
                <Label className='body-2 font-weight-bold'>
                  Nhóm SP web
                </Label>
                <TreeSelect
                  xl={12}
                  showSearch
                  treeNodeFilterProp='title'
                  style={{ width: '100%' }}
                  value={filter.web_cat}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={webCategories}
                  allowClear
                  treeDefaultExpandAll
                  onChange={(value) => setFilterValue(value, 'web_cat')}
                />
              </Col>


              <Col xl={4} className="mb-3">
                <Label className='body-2 font-weight-bold'>
                  Thương hiệu
                </Label>
                <TreeSelect
                  showSearch
                  treeNodeFilterProp='title'
                  style={{
                    width: '100%',
                  }}
                  value={filter.brand}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: 'auto',
                  }}
                  treeData={brands}
                  allowClear
                  treeDefaultExpandAll
                  onChange={(value) => setFilterValue(value, 'brand')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-3">
                <Row>
                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.no_web_cat}
                      onChange={(e) => setFilterValue(e.target.checked, 'no_web_cat')}
                    />
                    <label className='form-check-label'>
                      Không có nhóm web
                    </label>
                  </Col>

                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.no_brand}
                      onChange={(e) => setFilterValue(e.target.checked, 'no_brand')}
                    />
                    <label className='form-check-label'>
                      Không có brand
                    </label>
                  </Col>

                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.no_code_anh}
                      onChange={(e) => setFilterValue(e.target.checked, 'no_code_anh')}
                    />
                    <label className='form-check-label'>
                      Không có code ảnh
                    </label>
                  </Col>

                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.no_content}
                      onChange={(e) => setFilterValue(e.target.checked, 'no_content')}
                    />
                    <label className='form-check-label'>
                      Không có content
                    </label>
                  </Col>

                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.no_photo}
                      onChange={(e) => setFilterValue(e.target.checked, 'no_photo')}
                    />
                    <label className='form-check-label'>
                      Không có ảnh
                    </label>
                  </Col>

                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.tmp_image}
                      onChange={(e) => setFilterValue(e.target.checked, 'tmp_image')}
                    />
                    <label className='form-check-label'>
                      Có ảnh tạm
                    </label>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} className="mb-3">
                <Row>
                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.not_post_insta}
                      onChange={(e) => setFilterValue(e.target.checked, 'not_post_insta')}
                    />
                    <label className='form-check-label'>
                      Chưa post Insta
                    </label>
                  </Col>

                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.not_post_fb_24cara}
                      onChange={(e) => setFilterValue(e.target.checked, 'not_post_fb_24cara')}
                    />
                    <label className='form-check-label'>
                      Chưa post Casa
                    </label>
                  </Col>

                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.not_post_fb_24cara_hn}
                      onChange={(e) => setFilterValue(e.target.checked, 'not_post_fb_24cara_hn')}
                    />
                    <label className='form-check-label'>
                      Chưa post Casa HN
                    </label>
                  </Col>

                  <Col xs={2} className="form-check">
                    <Input type='checkbox'
                      checked={filter.not_post_fb_24cara_sg}
                      onChange={(e) => setFilterValue(e.target.checked, 'not_post_fb_24cara_sg')}
                    />
                    <label className='form-check-label'>
                      Chưa post Casa SG
                    </label>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} lg="auto">
                <Button color="primary" className="mr-3 mt-3" onClick={handleOnSearch}>
                  Tìm kiếm
                </Button>
                <Button color="secondary" className="mr-3 mt-3" onClick={handleResetFilter}>
                  Reset tìm kiếm
                </Button>
                <Button color="primary" className="mr-3 mt-3" onClick={handleOnExport}>
                  Kết xuất
                </Button>
              </Col>
            </Row>
          </>
          : null
      }
      <div className='tag color-primary font-weight-bold mt-3' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={onClose}>
        {
          isShowSearch ? "Đóng tìm kiếm" : "Tìm kiếm nâng cao"
        }
        {
          isShowSearch ?
            <i className="bx bx bx-chevron-up ml-2" />
            : <i className="bx bx bx-chevron-down ml-2" />
        }
      </div>
    </>

  );
}

ListProductFilter.propTypes = {
  location: PropTypes.object,
  populateProducts: PropTypes.func,
  updateQueryString: PropTypes.func,
};
export default withRouter(ListProductFilter);
