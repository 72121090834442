import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { useHistory, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { useSelector } from 'react-redux';
import * as permissionApi from "../../apis/permissionApi";
import { checkCMSPermision, checkPermision, getUser } from "../../constants/function";
import { utils } from 'Utils/utils';
import { CacheUtils } from 'Utils/CacheUtils';

const SidebarContent = props => {

  const [isAdmin, setAdmin] = useState();
  const [userGroups, setUserGroups] = useState();
  const selectedShop = useSelector(state => state.SelectedShop);
  const [listRole, setListRole] = useState([]);
  const refDiv = useRef();
  let history = useHistory();

  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const oul = document.getElementById("side-menu");
      const items = oul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();

  }, [isAdmin]);

  useEffect(() => {
    refDiv.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        refDiv.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul

          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  useLayoutEffect(() => {

    const user = getUser();
    if (!user) {
      localStorage.clear()
      history.push("/login")
      return
    }

    const roles = CacheUtils.getCachedRoles(selectedShop)
    if (roles) {
      setListRole(roles);
    } else {
      getListRoleFunction(selectedShop);
    }
    const adminStatus = user.is_admin === 1;
    setAdmin(adminStatus);
    setUserGroups(user.role);
  }, [selectedShop]);

  const getListRoleFunction = (selectedShop) => {
    permissionApi.getListModules(selectedShop, getDataSuccess, getDataFail);
  }
  const getDataSuccess = (res) => {
    setListRole(res.data.data);
    CacheUtils.setCachedRoles(selectedShop, res.data.data)
  }

  const getDataFail = (res) => {
    console.log("res", res);
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={refDiv}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="d-flex align-items-center">
                <i className="bx bx-home-circle" />
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            {
              checkPermision(isAdmin, listRole, "/inventories") &&
              <li>
                <Link to="/inventories" className="d-flex align-items-center">
                  <i className="bx bx-search-alt" />
                  <span>{props.t("Tra tồn")}</span>
                </Link>
              </li>
            }

            {
              (checkPermision(isAdmin, listRole, "/reports/dsht") ||
                checkPermision(isAdmin, listRole, "/reports/dsnv") ||
                checkPermision(isAdmin, listRole, "/reports/dsnsp") ||
                checkPermision(isAdmin, listRole, "/reports/dskho") ||
                checkPermision(isAdmin, listRole, "/reports/kholsnx") ||
                checkPermision(isAdmin, listRole, "/reports/khonxt") ||
                checkPermision(isAdmin, listRole, "/reports/banhang") ||
                checkPermision(isAdmin, listRole, "/reports/khachmuahang") ||
                checkPermision(isAdmin, listRole, "/reports/thoigianton")) ?
                <li>
                  <Link to="" className="has-arrow">
                    <i className="bx bxs-bar-chart-alt-2" />
                    <span>{props.t("Báo cáo")}</span>
                  </Link>
                  <ul className="sub-menu mm-collapse" aria-expanded="false">
                    {
                      checkPermision(isAdmin, listRole, "/reports/banhang") &&
                      <li>
                        <Link to="/reports/banhang">{props.t("Bán hàng")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/reports/khachmuahang") &&
                      <li>
                        <Link to="/reports/khachmuahang">{props.t("Khách mua hàng")}</Link>
                      </li>
                    }
                    <li>
                      <Link to="" className="has-arrow">{props.t("Doanh số")}</Link>
                      <ul className="sub-menu mm-collapse" aria-expanded="false">
                        {
                          checkPermision(isAdmin, listRole, "/reports/dsht") &&
                          <li>
                            <Link to="/reports/dsht">{props.t("DS hệ thống")}</Link>
                          </li>
                        }
                        {
                          checkPermision(isAdmin, listRole, "/reports/dsnv") &&
                          <li>
                            <Link to="/reports/dsnv">{props.t("DS nhân viên")}</Link>
                          </li>
                        }
                        {
                          checkPermision(isAdmin, listRole, "/reports/dsnsp") &&
                          <li>
                            <Link to="/reports/dsnsp">{props.t("DS nhóm SP")}</Link>
                          </li>
                        }
                        {
                          checkPermision(isAdmin, listRole, "/reports/dskho") &&
                          <li>
                            <Link to="/reports/dskho">{props.t("DS kho")}</Link>
                          </li>
                        }
                      </ul>
                    </li>
                    <li>
                      <Link to="" className="has-arrow">{props.t("Kho")}</Link>
                      <ul className="sub-menu mm-collapse" aria-expanded="false">
                        {
                          checkPermision(isAdmin, listRole, "/reports/kholsnx") &&
                          <li>
                            <Link to="/reports/kholsnx">{props.t("Lịch sử nhập xuất")}</Link>
                          </li>
                        }
                        {
                          checkPermision(isAdmin, listRole, "/reports/khonxt") &&
                          <li>
                            <Link to="/reports/khonxt">{props.t("Nhập xuất tồn")}</Link>
                          </li>
                        }
                        {
                          checkPermision(isAdmin, listRole, "/reports/thoigianton") &&
                          <li>
                            <Link to="/reports/thoigianton">{props.t("Thời gian tồn")}</Link>
                          </li>
                        }
                      </ul>
                    </li>
                  </ul>
                </li>
                : null
            }

            {
              (checkPermision(isAdmin, listRole, "/orders") ||
                checkPermision(isAdmin, listRole, "/returns") ||
                checkPermision(isAdmin, listRole, "/preorders") ||
                checkPermision(isAdmin, listRole, "/warehouse-receipts") ||
                checkPermision(isAdmin, listRole, "/warehouse-exports")) ?
                <li>
                  <Link to="" className="has-arrow">
                    <i className="bx bx-book-open" />
                    <span>{props.t("Hóa đơn/Nhập xuất")}</span>
                  </Link>
                  <ul className="sub-menu mm-collapse" aria-expanded="false">
                    {
                      checkPermision(isAdmin, listRole, "/orders") &&
                      <li>
                        <Link to="/orders">{props.t("Hóa đơn")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/returns") &&
                      <li>
                        <Link to="/returns">{props.t("Trả lại")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/preorders") &&
                      <li>
                        <Link to="/preorders">{props.t("Đặt hàng")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/warehouse-receipts") &&
                      <li>
                        <Link to="/warehouse-receipts">{props.t("Phiếu nhập")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/warehouse-exports") &&
                      <li>
                        <Link to="/warehouse-exports">{props.t("Phiếu xuất")}</Link>
                      </li>
                    }
                  </ul>
                </li>
                : null
            }
            {
              checkPermision(isAdmin, listRole, "/product-categories") ||
                checkPermision(isAdmin, listRole, "/attribute-groups") ||
                checkPermision(isAdmin, listRole, "/products") ||
                checkPermision(isAdmin, listRole, "/base-products") ||
                checkPermision(isAdmin, listRole, "/warehouses") ||
                checkPermision(isAdmin, listRole, "/shops") ||
                checkPermision(isAdmin, listRole, "/payment-methods") ||
                checkPermision(isAdmin, listRole, "/units") ||
                checkPermision(isAdmin, listRole, "/currencies") ||
                checkPermision(isAdmin, listRole, "/warehouse-receipt-types") ||
                checkPermision(isAdmin, listRole, "/warehouse-export-types") ||
                checkPermision(isAdmin, listRole, "/customers") ?
                <li>
                  <Link to="" className="has-arrow">
                    <i className="bx bx-list-ul" />
                    <span>{props.t("Danh mục")}</span>
                  </Link>
                  <ul className="sub-menu mm-collapse" aria-expanded="false">
                    {
                      checkPermision(isAdmin, listRole, "/web-categories") &&
                      <li>
                        <Link to="/web-categories">{props.t("Nhóm web")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/product-categories") &&
                      <li>
                        <Link to="/product-categories">{props.t("Nhóm sản phẩm")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/attribute-groups") &&
                      <li>
                        <Link to="/attribute-groups">{props.t("Quản lý thuộc tính")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/base-products") &&
                      <li>
                        <Link to="/base-products">{props.t("Sản phẩm cơ sở")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/products") &&
                      <li>
                        <Link to="/products">{props.t("Sản phẩm")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/warehouses") &&
                      <li>
                        <Link to="/warehouses">{props.t("Kho hàng")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/shops") &&
                      <li>
                        <Link to="/shops">{props.t("Cửa hàng")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/payment-methods") &&
                      <li>
                        <Link to="/payment-methods">{props.t("Thanh toán")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/units") &&
                      <li>
                        <Link to="/units">{props.t("Đơn vị tính")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/brands") &&
                      <li>
                        <Link to="/brands">{props.t("Thương hiệu")}</Link>
                      </li>
                    }
                    {/*
                      checkPermision(isAdmin, listRole, "/currencies") &&
                      <li>
                        <Link to="/currencies">{props.t("Tiền tệ")}</Link>
                      </li>
                    */}
                    {
                      checkPermision(isAdmin, listRole, "/warehouse-receipt-types") &&
                      <li>
                        <Link to="/warehouse-receipt-types">{props.t("Loại phiếu nhập")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/warehouse-export-types") &&
                      <li>
                        <Link to="/warehouse-export-types">{props.t("Loại phiếu xuất")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/customers") &&
                      <li>
                        <Link to="/customers">{props.t("Khách hàng")}</Link>
                      </li>
                    }
                  </ul>
                </li>
                : null
            }
            {
              checkPermision(isAdmin, listRole, "/sale-campaigns") ?
                <li>
                  <Link to="" className="has-arrow">
                    <i className="bx bx-star" />
                    <span>{props.t("Khuyến mại")}</span>
                  </Link>
                  <ul className="sub-menu mm-collapse" aria-expanded="false">
                    <li>
                      <Link to="/sale-campaigns">{props.t("Danh sách")}</Link>
                    </li>
                    <li>
                      <Link to="/sale-campaigns/find">{props.t("Tìm sản phẩm")}</Link>
                    </li>
                  </ul>
                </li>
                : null
            }
            {
              checkPermision(isAdmin, listRole, "/users") ||
                checkPermision(isAdmin, listRole, "/roles") ||
                checkPermision(isAdmin, listRole, "/permissions") ?
                <li>
                  <Link to="" className="has-arrow">
                    <i className="bx bx-cog" />
                    <span>{props.t("Hệ thống")}</span>
                  </Link>
                  <ul className="sub-menu mm-collapse" aria-expanded="false">
                    {
                      checkPermision(isAdmin, listRole, "/users") &&
                      <li>
                        <Link to="/users">{props.t("Danh sách người dùng")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/user-warehouses") &&
                      <li>
                        <Link to="/user-warehouses">{props.t("Phân quyền kho")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/roles") &&
                      <li>
                        <Link to="/roles">{props.t("Nhóm quyền")}</Link>
                      </li>
                    }
                    {
                      checkPermision(isAdmin, listRole, "/permissions") &&
                      <li>
                        <Link to="/permissions">{props.t("Phân quyền")}</Link>
                      </li>
                    }
                    {
                      <li>
                        <Link to="/system-configuration">{props.t("Cấu hình hệ thống")}</Link>
                      </li>
                    }
                  </ul>
                </li>
                : null
            }
            {
              false &&
              <li>
                <Link to="" className="has-arrow">
                  <i className="bx bx-sun" />
                  <span>{props.t("CMS Casa")}</span>
                </Link>
                <ul className="sub-menu mm-collapse" aria-expanded="false">
                  <li>
                    <Link to="/web-orders">{props.t("Đơn online")}</Link>
                  </li>
                  <li>
                    <Link to="/banner">{props.t("Banner")}</Link>
                  </li>
                  <li>
                    <Link to="/support-request">{props.t("Yêu cầu tư vấn")}</Link>
                  </li>
                  <li>
                    <Link to="/feedback">{props.t("Khách phản hồi")}</Link>
                  </li>
                  <li>
                    <Link to="/shop-address">{props.t("Địa chỉ cửa hàng")}</Link>
                  </li>
                  <li>
                    <Link to="/category-menu">{props.t("Menu danh mục")}</Link>
                  </li>
                  <li>
                    <Link to="/footer-menu">{props.t("Menu cuối trang")}</Link>
                  </li>
                </ul>
              </li>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
  // }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
